import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { SectionHeading } from '@components/ui/heading'
import { cloudfrontPath } from '@utils/image'
import { HOME } from '../constant'

type Props = typeof HOME.pointSection.common

export const PointSection = ({ heading, contentItems }: Props): JSX.Element => {
  return (
    <section className="mt-[21vw] lg:mt-[7vw]">
      <SectionHeading {...{ ...heading }} />
      <div className="mx-[4.8vw] lg:mx-[7.1vw] mt-[16.8vw] lg:mt-[7.1vw]">
        {contentItems.map((item, index) => (
          <div
            className={`gap-x-12 ${
              index % 2 ? 'flex lg:flex-row-reverse' : 'flex lg:flex-row'
            } ${
              index > 0 ? 'mt-[11.2vw] lg:mt-[4.6vw]' : ''
            } flex-col bg-white card-box-shadow lg:px-[4vw] lg:py-[3.1vw] rounded-3xl relative py-[5.3vw] px-[4.2vw]`}
            key={index}
          >
            <p className="flex absolute top-[-5vw] lg:top-[-3.1vw] left-[-4vw] lg:left-[-3.1vw] justify-center items-center w-[11.7vw] lg:w-[6.25vw] h-[11.7vw] lg:h-[6.25vw] text-[5.6vw] lg:text-[3vw] font-bold text-white bg-black bg-gradient-to-r from-primaryTo to-primaryFrom rounded-full card-box-shadow">
              0{index + 1}
            </p>
            <div className="w-full lg:w-[45.2vw]">
              <h3 className="text-[4.2vw] lg:text-[2.3vw] font-bold tracking-wider leading-relaxed lg:leading-loose text-center lg:text-left whitespace-pre lg:whitespace-normal">
                {item.title}
              </h3>
              <p className="mt-[5vw] lg:mt-[1.5vw] text-[3.2vw] lg:text-[1.25vw] font-bold tracking-widest leading-loose">
                {item.description}
              </p>
            </div>
            <div className="flex-1 mt-[6.4vw] lg:mt-0">
              <ImageWraper
                src={`${cloudfrontPath}/${item.src}`}
                alt=""
                width={364}
                height={186}
              />
            </div>
          </div>
        ))}

        <section className="mt-[13.3vw] lg:mt-[4.6vw]">
          <h3 className="text-[4.8vw] lg:text-[2vw] font-bold tracking-wider text-center text-[#EB8435]">
            バヅクリの人材育成/組織開発セオリー
          </h3>
          <p className="lg:hidden mt-[7.1vw] text-[4.2vw] font-bold tracking-widest text-center">
            従来の人材育成/組織開発
          </p>
          <div className="lg:flex lg:items-center lg:mt-[3.1vw]">
            <div className="">
              <p className="hidden lg:block lg:text-[1.25vw] font-bold tracking-wider text-center">
                従来の人材育成/組織開発
              </p>
              <div className="mx-auto mt-[4.2vw] lg:mt-[2.1vw] w-[58.9vw] lg:w-[17.2vw]">
                <ImageWraper
                  src={`${cloudfrontPath}/images/top/point_20240409162638.png`}
                  alt=""
                  width={221}
                  height={203}
                />
              </div>
            </div>
            <div className="mx-auto mt-[4.2vw] lg:mt-0 w-[10.6vw] lg:w-[2.8vw] bg-[#F0B430] triangle-downturn lg:triangle-rightTurn"></div>
            <div className="mt-[4.2vw] lg:mt-0 w-[90.4vw] lg:w-[57.2vw]">
              <ImageWraper
                src={`${cloudfrontPath}/images/top/point_20240409162658.png`}
                alt=""
                width={339}
                height={188}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}
