import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

export const DownloadIcon = (): JSX.Element => {
  return (
    <ImageWraper
      src={`${cloudfrontPath}/images/top/download-gray.svg`}
      width={23}
      height={17}
    />
  )
}
