import { cloudfrontPath } from '@utils/image'

const ABOUT_SECTION_IMG = {
  pcfileName: 'connection_circle@2x.png',
  spfileName: 'connection_circle_sp@2x.png',
  alt: 'バヅクリが提供する“つながり”と“能力”',
}

export const HOME = {
  aboutSection: {
    common: {
      heading: {
        mainHeading: ``,
        extraHeading: 'What We do',
      },
      title: `バヅクリは人材育成/組織開発の戦略策定から施策実行、\n効果測定まで一貫してエンゲージメント向上を支援します。`,
      description: ``,
    },
    pc: {
      image: {
        src: `${cloudfrontPath}/programs/icons/top/${ABOUT_SECTION_IMG.pcfileName}`,
        alt: ABOUT_SECTION_IMG.alt,
        width: 801,
        height: 755,
      },
    },
    sp: {
      image: {
        src: `${cloudfrontPath}/programs/icons/top/${ABOUT_SECTION_IMG.spfileName}`,
        alt: ABOUT_SECTION_IMG.alt,
        width: 537,
        height: 507,
      },
    },
  },
  serviceSection: {
    common: {
      heading: {
        mainHeading: ``,
        extraHeading: 'SERVICE',
      },
      cardItems: [
        {
          title: `エンゲージメントサーベイ`,
          description:
            '4分の回答時間でスコアの低下要因まで特定できるエンゲージメントサーベイです。属性/クロス/推移/要因分析と高度で多角的な分析が可能で、各部門へのフィードバックサポート、改善施策までセットで提供しています。',
          src: 'images/top/service_20240409182255.png',
          href: 'https://hr.buzzkuri.com/engagement',
        },
        {
          title: `研修`,
          description:
            '行動変容にこだわった “平均ワーク時間70%” × “チームビルディング” 研修を提供しています。一般的な研修だけではカバーしきれない、実務のスキマで必要となる実践的なコミュニケーション能力の習得が可能です。',
          src: 'images/top/service_20240409182320.png',
          href: 'https://hr.buzzkuri.com/training',
        },
        {
          title: 'エンゲージメント向上支援',
          description:
            'エンゲージメント向上のための目標/戦略設計から計画策定、施策実行、効果測定まで組織開発を一気通貫で支援します。エンゲージメントサーベイの結果をもとに最適な施策の提案と実行・運用までフルサポートします。',
          src: 'images/top/service_20240409182340.png',
          href: 'https://hr.buzzkuri.com/engagement2',
        },
        {
          title: '内定者フォロー',
          description:
            '内定者同士の関係構築により、入社前の不安解消と入社までのモチベーション向上をサポートし、内定承諾率の向上や内定辞退の防止を支援します。',
          src: 'images/top/service_20240409182345.png',
          href: 'https://tb.buzzkuri.com/naiteisha/',
        },
      ],
    },
  },
  pointSection: {
    common: {
      heading: {
        mainHeading: `バヅクリの強み`,
        extraHeading: 'POINT',
      },
      contentItems: [
        {
          title: `心を動かす実践的研修で\n学びを定着化`,
          description: `バヅクリの研修は教科書的な座学だけではなく、実用的なテーマで実践・フィードバック中心のアクティブラーニングをベースに構成されています。これにより、学習内容の定着率が91%と極めて高い水準を実現しています。`,
          src: 'images/top/point_20240409165351.png',
        },
        {
          title: `仲間との絆で\n行動変容を促す`,
          description: `研修後のアクションプランの作成だけで終わらせることなく、バヅクリでは、アクションプランを参加者同士が約束することで、有機的に行動変容が生まれます。`,
          src: 'images/top/point_20240409162606.png',
        },
        {
          title: `一気通貫で\nエンゲージメント課題を解決`,
          description: `課題抽出から対策立案、実行まで一貫してエンゲージメント向上を支援します。エンゲージメントサーベイの結果をもとに最適な施策の提案と実行・運用までフルサポートします。`,
          src: 'images/top/point_20240409162615.png',
        },
        {
          title: `本当に届けたい\n社員を巻き込む`,
          description: `バヅクリが提供する200種類以上の研修/ワークショップは、普段研修に参加したがらない後ろ向きな社員にも、主体的な関心を引き出しながら施策の目的や狙いを理解させ、現場を巻き込むことができます。`,
          src: 'images/top/point_20240409165405.png',
        },
      ],
    },
  },
  popularityProgramsSection: {
    common: {
      heading: {
        extraHeading: 'POPULARITY PROGRAM',
        mainHeading: '人気の研修/ワークショップ',
      },
    },
  },
  seminarSection: {
    common: {
      heading: {
        extraHeading: 'SEMINAR',
        mainHeading: 'セミナー・体験会',
      },
    },
  },
  adviserSection: {
    common: {
      heading: {
        extraHeading: 'VOICE',
        mainHeading: 'バヅクリに対する有識者のご意見',
      },
      advisers: [
        {
          name: '佐藤 邦彦',
          label: `元リクルートワークス研究所『Works』編集長\n現Thinkings株式会社 執行役員CHRO`,
          img: '/images/top/adviser_01_v1.png',
          title: `最適化されたサーベイと現場を巻き込む効果的な施策で\n人的資本経営におけるエンゲージメント向上を実現`,
          description: '',
          points: [
            {
              title: '①課題をスピーディーかつシンプルに可視化',
              message:
                'バヅクリのエンゲージメントサーベイはモチベーション理論、人的資本経営のISO30414、人事担当者へのリサーチに基づいた網羅的かつ効率的な項目設計により、シンプルでわかりやすい課題の可視化が可能です。設問が多くサーベイ自体が複雑化し、課題の本質が明確化しづらいツールが多い中、バヅクリはサーベイ自体が短時間で負担をかけずに回答でき、アウトプットもシンプルかつ本質的なため、組織課題に対して迅速にアプローチできます。具体的な施策が明示されるため、自立型人材の育成や多様性理解、心理的安全性の醸成など、幅広い施策が求められるVUCA時代のニーズにマッチした内容となっています。',
            },
            {
              title: '②課題抽出から施策実行まで一貫したアジャイル対応',
              message:
                '課題がシンプルでわかりやすいだけでなく、連動した改善施策までバヅクリのサービス内で完結できるため、人事担当者が悩むことなくスムーズに施策に落とし込めます。年に3、4回のサイクルでサーベイを実施しながら、必要に応じて軌道修正を行うことで、最適な状態を保ちながら組織課題の解決に取り組むことができます。',
            },
            {
              title: '③本当に届けたい層への巻き込みが可能',
              message:
                'バヅクリの施策は現場をいかに巻き込むか、を重視しています。コミュニケーションに課題を抱えているメンバーに対して趣味対話やグラレコなど、本来の課題からすこしズレたテーマでアプローチすることによって、一般的な研修をストレートに実施するよりも主体的な関心を引き出すことができます。具体的なプログラムも現代の組織に共通する課題を取り上げているため、躊躇することなく気軽に参加できるよう工夫されています。参加者同士の心理的安全性の担保や信頼関係の醸成を通じて、エンゲージメントの向上や、組織の生産性の向上が期待できます。',
            },
          ],
        },
        {
          name: '堤 多可弘',
          label: '産業医、精神科医、医学博士',
          img: '/images/top/supervision-img.png',
          title: `バヅクリは、「インフラは整ったのに心がついていかない」\nというコロナ禍以降の課題を解決できるサービスです。`,
          description:
            'コンテンツは絆、学び、アソビをベースに作られていて、中でもアソビは余白を埋めるものではなく、大事なのりしろだと感じています。つまり単なる余暇や暇つぶし、気分転換にとどまらず実は仕事を円滑に進めるうえでも非常に重要であるという意味です。以下、3つのポイントに分けて説明します。',
          points: [
            {
              title: '①孤独を解消し、職場に”仲間”がいることの重要性を実感',
              message:
                'コロナ以降問題となっている孤独に対する効果が大きく、生産性の低下やメンタル不調、離職に対する解決策になります。双方向かつグループワークなどをたくさん取り入れており、ファシリテーターが場をコントロールするのでやり取りもスムーズになり、相互のコミュニケーションが生まれやすくなっています。こうした仕掛けから孤独の解消につながるのではないかと考えています。',
            },
            {
              title: '②心理的安全性の醸成',
              message:
                'コロナ禍以降ではオフコミュニケーションが減少し、、お互いの人柄がわかりづらくなりました。すると、自然とコミュニケーションがギクシャクとしてしまい信頼関係が成立せず、相手が伝えたい思いやニュアンスが読み取りづらくなります。バヅクリのプログラムを体験すると仕事以外でのコミュニケーション量が増え、その結果心理的安全性も醸成されることになります。また、仕事以外での共通の話題を持つことで、研修効果が長続きするとも考えられます。心理的安全性や仲間意識などのつながりができることで、モチベーション・エンゲージメントが向上し、自然と生産性も向上します。',
            },
            {
              title: '③イノベーションの促進',
              message:
                '変化の大きい現代ではイノベーションが求められています。仕事とは違うアソビというルールの中では別の視点が生まれやすいです。子供時代を思い出してみると、公園の片隅にあるちょっとした茂みでできた空間を秘密基地と呼んでみたり、本来乗り物である3輪車を逆さまにしてかき氷機に見立ててみたり。大人では考えられないような発想・イノベーションを子供達は常に起こしています。こうしたことから、アソビがイノベーションに重要であると考えられます。これらをやらされ感なく自然に実現できることが、バヅクリの何よりの強みであると感じています。',
            },
          ],
          profile: {
            name: '産業医、精神科医、医学博士 | 堤 多可弘',
            description:
              '弘前大学医学部卒業後、東京女子医科大学精神科で助教、非常勤講師を歴任。現在はVISION PARTNERメンタルクリニック四谷の副院長を務めるとともに、企業や行政機関の産業医を複数こなしている。ブログや著作、Web媒体や研修などを通じて、メンタルヘルスや健康経営に関する情報発信も行っている。ビジネスパーソンのメンタルへルス相談に定評がある。',
            book: {
              link: 'https://www.amazon.co.jp/%E4%BC%81%E6%A5%AD%E3%81%AF%E3%83%A1%E3%83%B3%E3%82%BF%E3%83%AB%E3%83%98%E3%83%AB%E3%82%B9%E3%81%A8%E3%81%A9%E3%81%86%E5%90%91%E3%81%8D%E5%90%88%E3%81%86%E3%81%8B-%E7%B5%8C%E5%96%B6%E6%88%A6%E7%95%A5%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E7%94%A3%E6%A5%AD%E5%8C%BB-%E7%A5%A5%E4%BC%9D%E7%A4%BE%E6%96%B0%E6%9B%B8-%E5%B0%BE%E6%9E%97%E8%AA%89%E5%8F%B2/dp/4396116047',
              reference:
                '「企業はメンタルヘルスとどう向き合うか―経営戦略としての産業医」(祥伝社新書)',
            },
          },
        },
      ],
    },
  },

  programSection: {
    common: {
      heading: {
        extraHeading: 'PROGRAM',
        mainHeading: '目的から探す',
      },
      moreBtn: {
        size: 'normal' as 'normal' | 'large',
        text: 'プログラム一覧を見る',
      },
    },
  },
  firstViewSection: {
    pc: {
      title: `心を動かす学びと絆で\nヒトと組織を変える`,
      description: `”向き合い方”と”つながり”でエンゲージメントを高める`,
      titles: [
        {
          title: `仲間作りで組織に成長と変革を\n社内イベントDX・若手研修DX`,
          subTitle: '',
          TitleTag: 'h1' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h2' as keyof JSX.IntrinsicElements,
          linkUrl: '/programs',
        },
        {
          title: `絆・学び・アソビで仲間作り\n内定辞退と早期退職を防止`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E5%86%85%E5%AE%9A%E8%80%85%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC',
        },
        {
          title: `世代と拠点を超えてオンラインで楽しむ\n業界最大級 100種類の労組イベント`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E5%8A%B4%E5%83%8D%E7%B5%84%E5%90%88%E3%83%AC%E3%82%AF%E3%83%AA%E3%82%A8%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3',
        },
        {
          title: `プロが盛り上げる全員参加型社内イベント\n100種類の企画&当日進行は丸投げOK`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E7%A4%BE%E5%86%85%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88',
        },
        {
          title: `仲間作りで行動変容コミット\n新時代を切り拓く課題解決型研修DX`,
          subTitle: 'ダイバーシティ、キャリアデザイン、レジリエンス',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl: '/programs/%E7%A0%94%E4%BF%AE',
        },
      ],
      image: {
        width: 1280,
        height: 626,
        src: [
          // `${cloudfrontPath}/programs/icons/top/top_first_view_1@2x.png`,
          // `${cloudfrontPath}/programs/icons/top/top_first_view_2@2x.png`,
          // `${cloudfrontPath}/programs/icons/top/top_first_view_3@2x.png`,
          // `${cloudfrontPath}/programs/icons/top/top_first_view_4@2x.png`,
          // `${cloudfrontPath}/programs/icons/top/top_first_view_5@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-01@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-02@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-03@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-04@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-05@2x.png`,
        ],
      },
      display: {
        isDot: true,
        isPromptScrolling: true,
      },
    },
    sp: {
      title: `心を動かす学びと絆で\nヒトと組織を変える`,
      description: `”向き合い方”と”つながり”で\nエンゲージメントを高める`,
      titles: [
        {
          title: `仲間作りで\n組織に成長と変革を\n社内イベントDX・若手研修DX`,
          subTitle: '',
          TitleTag: 'h1' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h2' as keyof JSX.IntrinsicElements,
          linkUrl: '/programs',
        },
        {
          title: `絆・学び・アソビで\n仲間作り\n内定辞退と早期退職を防止`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E5%86%85%E5%AE%9A%E8%80%85%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC',
        },
        {
          title: `世代と拠点を超えて\nオンラインで楽しむ\n業界最大級\n100種類の労組イベント`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E5%8A%B4%E5%83%8D%E7%B5%84%E5%90%88%E3%83%AC%E3%82%AF%E3%83%AA%E3%82%A8%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3',
        },
        {
          title: `プロが盛り上げる\n全員参加型社内イベント\n100種類の企画&\n当日進行は丸投げOK`,
          subTitle: '',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl:
            '/programs/%E7%A4%BE%E5%86%85%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88',
        },
        {
          title: `仲間作りで行動変容コミット\n新時代を切り拓く\n課題解決型研修DX`,
          subTitle: 'ダイバーシティ、キャリアデザイン、\nレジリエンス',
          TitleTag: 'h2' as keyof JSX.IntrinsicElements,
          SubTitleTag: 'h3' as keyof JSX.IntrinsicElements,
          linkUrl: '/programs/%E7%A0%94%E4%BF%AE',
        },
      ],
      image: {
        width: 375,
        height: 724,
        src: [
          `${cloudfrontPath}/images/top/top-slider-01-sp@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-02-sp@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-03-sp@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-04-sp@2x.png`,
          `${cloudfrontPath}/images/top/top-slider-05-sp@2x.png`,
        ],
      },
      display: {
        isDot: false,
        isPromptScrolling: false,
      },
    },
  },
  arrivalColumnsSection: {
    common: {
      heading: {
        extraHeading: 'COLUMN',
        mainHeading: 'コラム',
      },
      moreBtn: {
        size: 'normal' as 'normal' | 'large',
        text: 'コラム一覧を見る',
      },
    },
  },
}
