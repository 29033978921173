import { BtnWithCircleArrow } from '@components/ui/btn'
import { SectionHeading } from '@components/ui/heading'
import { FeedItem } from '@type/buzzkuri/home'
import React from 'react'
import { FeedItemCards } from './Cards'

type Props = {
  heading: {
    mainHeading: string
    extraHeading: string
  }
  feedItems: FeedItem[]
  moreBtn: {
    size: 'normal' | 'large'
    text: string
  }
}

export const ArrivalColumnsSection = ({
  heading,
  feedItems,
  moreBtn,
}: Props): JSX.Element => {
  return (
    <section className="mt-[19.2vw] lg:mt-[7.5vw]">
      <SectionHeading {...{ ...heading }} />
      <FeedItemCards {...{ feedItems }} />
      <BtnWithCircleArrow
        text={moreBtn.text}
        link="https://buzzkuri.com/columns"
      />
    </section>
  )
}
