import { SeoMetadata } from '@components/shared/common/SeoMetadata'
import { MetaData } from '@type/buzzkuri/api'
import Head from 'next/head'
import React from 'react'
import { YahooAd } from '../../script/yahoo'

type Props = {
  isSiteTop?: boolean
  metaData: MetaData
}

export const BuzzkuriHead = ({ isSiteTop, metaData }: Props): JSX.Element => (
  <>
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {isSiteTop && (
        <>
          <meta
            content="8kg2gdc08ccq8d0h2cga728r3f68yw"
            name="facebook-domain-verification"
          />
          <meta
            content="Ffv0rjNQ7wKUGgxZdqCxe8WR1O1KxxrRcwkW1Kwn1fU"
            name="google-site-verification"
          />
        </>
      )}
      <YahooAd />
    </Head>
    <SeoMetadata {...metaData} />
  </>
)
