type Props = {
  widthClass: string
}

export const OrangeGradientDivide = ({ widthClass }: Props): JSX.Element => {
  return (
    <div
      className={`${widthClass} h-[2px] bg-gradient-to-r from-primaryTo to-primaryFrom`}
    />
  )
}
