import React from 'react'
import { ProgramCards } from './Cards'
import { HOME } from '../constant'
import { SectionHeading } from '@components/ui/heading'
import { BtnWithCircleArrow } from '@components/ui/btn'
export const ProgramSection = (): JSX.Element => {
  return (
    <section className="mt-[17vw] lg:mt-[8.8vw]">
      <SectionHeading {...{ ...HOME.programSection.common.heading }} />
      <div className="mx-[4.8vw] lg:mx-[7.1vw]">
        <ProgramCards {...{ programs: PROGRAMS }} />
        <BtnWithCircleArrow
          text={HOME.programSection.common.moreBtn.text}
          link="/programs"
        />
      </div>
    </section>
  )
}

const PROGRAMS = [
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/1/images/0_7numplgym5t.jpg',
    name: '内定者フォロー',
    pagePath:
      'https://buzzkuri.com/programs/%E5%86%85%E5%AE%9A%E8%80%85%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC',
  },
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/2/images/0_9ih2ut0vjgp.png',
    name: '新人・若手社員研修',
    pagePath:
      'https://buzzkuri.com/programs/%E6%96%B0%E4%BA%BA%E3%83%BB%E8%8B%A5%E6%89%8B%E7%A4%BE%E5%93%A1%E7%A0%94%E4%BF%AE',
  },
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/3/images/0_uxiu5u11e09.png',
    name: 'チームビルディング',
    pagePath:
      'https://buzzkuri.com/programs/%E3%83%81%E3%83%BC%E3%83%A0%E3%83%93%E3%83%AB%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0',
  },
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/4/images/0_a0poq6gwzqj.png',
    name: '社内イベント',
    pagePath:
      'https://buzzkuri.com/programs/%E7%A4%BE%E5%86%85%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88',
  },
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/5/images/0_nd14dtav8dl.png',
    name: '労働組合レクリエーション',
    pagePath:
      'https://buzzkuri.com/programs/%E5%8A%B4%E5%83%8D%E7%B5%84%E5%90%88%E3%83%AC%E3%82%AF%E3%83%AA%E3%82%A8%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3',
  },
  {
    imageFilePath:
      'https://d2c9x2x0n5546t.cloudfront.net/clubs/programs/objectives/6/images/motivation_up_v1.jpg',
    name: 'エンゲージメント向上',
    pagePath:
      'https://buzzkuri.com/programs/%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E5%90%91%E4%B8%8A',
  },
]
