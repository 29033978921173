import { BtnWithCircleArrow } from '@components/ui/btn'
import { CaseCardGroups } from '@components/ui/card/CaseCard/CaseCardGroups'
import { SectionHeading } from '@components/ui/heading'
import { CaseSliderGroups } from '@components/ui/slider'
import { ScreenTarget } from '@type/buzzkuri/home'
import { CASE_SECTION_HEADING } from './conf'

type Props = {
  screenTarget: ScreenTarget
}

export const CaseSection = ({ screenTarget }: Props): JSX.Element => {
  return (
    <section className="py-[17vw] lg:py-[7.8vw] bg-[#F7F7F7] rounded-b-[12.5vw]">
      <SectionHeading {...{ ...CASE_SECTION_HEADING }} />
      {screenTarget === 'pc' && <CaseCardGroups {...{ screenTarget }} />}
      {screenTarget === 'sp' && <CaseSliderGroups {...{ screenTarget }} />}
      <BtnWithCircleArrow text="導入事例一覧を見る" link="/cases" />
    </section>
  )
}
