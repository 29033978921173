import { cloudfrontPath } from '@utils/image'
import { CaseThumbnail } from './type'

export const CASE_CAMPANISE: CaseThumbnail[] = [
  {
    title: `「バヅクリ」で内定者同士の\nつながりを創り、入社前の不安を解消`,
    campanyName: '株式会社みずほフィナンシャルグループ',
    kv: `${cloudfrontPath}/images/case_study/4/kv.jpg`,
    logo: {
      src: `${cloudfrontPath}/images/top/case01_logo.png`,
      width: 522,
      height: 261,
    },
    link: '/cases/4',
    logoContainerWidth: {
      pc: 77,
      sp: 64,
    },
  },
  {
    title: `300名の内定者交流を\n約半月分の人事工数削減で実現`,
    campanyName: '三井住友海上火災保険株式会社',
    kv: `${cloudfrontPath}/images/case_study/3/e968b375-54e5-4dd6-aaf0-b8c0115e9a19.jpg`,
    logo: {
      src: `${cloudfrontPath}/images/top/case02_logo.png`,
      width: 488,
      height: 292,
    },
    link: '/cases/3',
    logoContainerWidth: {
      pc: 64,
      sp: 55,
    },
  },
  {
    title: `会社全体として\n個人のウェルビーイングを高める活動`,
    campanyName: 'デロイト トーマツ ファイナンシャルアドバイザリー合同会社',
    kv: `${cloudfrontPath}/images/top/case_20240410191320.jpg`,
    logo: {
      src: `${cloudfrontPath}/images/top/case_20240410204200.png`,
      width: 280,
      height: 160,
    },
    link: '/cases/50',
    logoContainerWidth: {
      pc: 78,
      sp: 65,
    },
  },
  {
    title: `社歴・年代・部門の垣根を\n超えた関係構築で心理的安全性を醸成`,
    campanyName: 'TIS株式会社',
    kv: `${cloudfrontPath}/images/top/case_20240410193700.jpg`,
    logo: {
      src: `${cloudfrontPath}/images/top/case_20240410193720.png`,
      width: 1920,
      height: 469,
    },
    link: '/cases/57',
    logoContainerWidth: {
      pc: 97,
      sp: 81,
    },
  },
  {
    title: `社員の自己実現とダイバーシティ推進の\nためのエンゲージメント向上施策`,
    campanyName: '株式会社パスコ',
    kv: `${cloudfrontPath}/images/top/case_20240410194000.png`,
    logo: {
      src: `${cloudfrontPath}/images/top/case_20240410193900.png`,
      width: 102,
      height: 38,
    },
    link: '/cases/58',
    logoContainerWidth: {
      pc: 52,
      sp: 44,
    },
  },
  {
    title: `多様化・複雑化する組合活動の\nニーズに応えるための新たな取り組み`,
    campanyName: 'トヨタ自動車労働組合',
    kv: `${cloudfrontPath}/images/top/case_20240410204300.jpg`,
    logo: {
      src: ``,
      width: 194,
      height: 214,
    },
    link: '/cases/43',
    logoContainerWidth: {
      pc: 43,
      sp: 36,
    },
  },
]
