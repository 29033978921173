import { DocumentRequestBtn } from '@components/buzzkuri/shared/DocumentRequestBtn'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'
import { HOME } from '../constant'

export const HomeFirstViewSection: React.FC = () => (
  <>
    <section className="relative mt-[20vw] lg:mt-[7.2vw] h-[170vw] lg:h-[44.7vw]">
      <div className="hidden lg:block relative top-0 left-0 w-full lg:h-[44.7vw]">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/first-view-pc_20240409161200.png`}
          alt=""
          width={1280}
          height={572}
          priority
        />
      </div>
      <div className="lg:hidden relative top-0 left-0 w-full h-[161vw] ">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/first-view-sp_20240409161200.png`}
          alt=""
          width={375}
          height={605}
          priority
        />
      </div>
      <div className="absolute top-0 lg:top-1/2 py-[18.4vw] lg:py-0 px-[5.3vw] lg:px-0 lg:pl-[2.3vw] lg:translate-y-[-50%]">
        <h2 className="text-[9vw] lg:text-[5vw] font-bold leading-snug lg:leading-tight text-transparent whitespace-pre bg-clip-text bg-gradient-to-r from-[#EEAC38] to-[#E6553A]">
          {HOME.firstViewSection.pc.title}
        </h2>
        <p className="hidden lg:block mt-[5.3vw] lg:mt-[3vw] text-[4.2vw] lg:text-[1.5vw] font-bold tracking-widest leading-none">
          {HOME.firstViewSection.pc.description}
        </p>
        <p className="lg:hidden mt-[5.3vw] text-[4.2vw] font-bold tracking-widest leading-normal whitespace-pre">
          {HOME.firstViewSection.sp.description}
        </p>
        <DocumentRequestBtn
          title="無料資料ダウンロード"
          customDataAttribute={{
            attributeName: 'data-document-download',
            attributeValue: 'ファーストビュー',
          }}
          classes="mt-[8vw] w-[62.9vw] lg:mt-[2.73vw] rounded-[50px] lg:text-[1.6vw] p-[3.7vw] lg:p-[1.5vw] text-[3.7vw] lg:w-[25.3vw] shadow-[#00000042] shadow-md mx-auto lg:mx-0"
        />
      </div>
      <div className="lg:hidden absolute after:absolute after:top-0 bottom-0 left-1/2 mx-auto w-[83.2vw] after:w-full after:h-full after:bg-white after:rounded-full after:opacity-90 after:blur translate-x-[-50%] after:content-['']">
        <div className="relative z-10 mx-auto w-[43.7vw] h-[24.2vw]">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/batch-frame_20240409161900.png`}
            alt=""
            width={164}
            height={91}
          />
          <p className="flex absolute top-1/2 left-1/2 z-20 flex-col justify-center items-center w-full h-full translate-x-[-50%] translate-y-[-50%]">
            <span className="text-[2.6vw] font-bold tracking-wide leading-none">
              導入企業数
            </span>
            <span className="text-[9vw] font-bold tracking-wider leading-tight text-[#000000CC]">
              800
            </span>
            <span className="text-[2.6vw] font-bold tracking-wide leading-none">
              社以上
            </span>
          </p>
        </div>
      </div>
    </section>
  </>
)
