import { FeedItem } from '@type/buzzkuri/home'
import React from 'react'
import { FeedItemCard } from '../Card'

type Props = {
  feedItems: FeedItem[]
}

export const FeedItemCards = ({ feedItems }: Props): JSX.Element => {
  return (
    <div className="flex flex-col lg:flex-row flex-wrap lg:gap-x-[2vw] lg:gap-y-[4.5vw] justify-between lg:justify-center mx-[12.8vw] lg:mx-[11vw] mt-[8.5vw] lg:mt-[3.8vw]">
      {feedItems.map((feedItem, i) => (
        <FeedItemCard
          key={i}
          title={feedItem.title}
          description=""
          href={feedItem.link}
          imgSize={{ w: 343, h: 185 }}
          cardRowNum={3}
          imagePath={feedItem.image}
        />
      ))}
    </div>
  )
}
