export const isValidProps = (props: unknown): boolean => {
  if (props) {
    if (typeof props === 'object') {
      if (Array.isArray(props)) {
        return props.length !== 0
      } else {
        return !(
          Object.keys(props).length === 0 &&
          Object.getPrototypeOf(props) === Object.prototype
        )
      }
    } else {
      return Boolean(props)
    }
  } else {
    return false
  }
}
