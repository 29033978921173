import { HomePageRes } from '@type/buzzkuri/home'
import { HomePage } from '@components/buzzkuri/pages/top'
import React, { useMemo } from 'react'
import { GetServerSidePropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import { getPagePropsReq } from '@utils/http'
import { BuzzkuriHead } from '@components/buzzkuri/shared/common/BuzzkuriHead'
import { ErrorStatus } from '@type/common'
import { ALTERNATE_DATA_AT_ERROR } from '@constants/common'
import { MetaData } from '@type/buzzkuri/api'

const Home = (props: HomePageRes | ErrorStatus): JSX.Element => {
  // error発生時には静的部分のみ表示するように代替データを使う
  const homePageProps: HomePageRes = useMemo(
    () =>
      'errorStatus' in props ? ALTERNATE_DATA_AT_ERROR : (props as HomePageRes),
    [props]
  )

  return (
    <>
      <BuzzkuriHead metaData={homePageProps.metaData as MetaData} isSiteTop />
      <HomePage {...homePageProps} />
    </>
  )
}

export default Home

export async function getServerSideProps(
  context: GetServerSidePropsContext<ParsedUrlQuery>
): Promise<{ props: HomePageRes | ErrorStatus }> {
  return getPagePropsReq(context, '/api/home') as Promise<{
    props: HomePageRes | ErrorStatus
  }>
}
