import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { SectionHeading } from '@components/ui/heading'
import { cloudfrontPath } from '@utils/image'
import React from 'react'
import { HOME } from '../constant'

type Props = typeof HOME.aboutSection.common

export const AboutSection = ({ heading, title }: Props): JSX.Element => {
  return (
    <section className="mt-[9.6vw] lg:mt-[7.4vw]">
      <SectionHeading {...heading} />
      <div className="mx-[4.8vw] lg:mx-[7.1vw] mt-[5.6vw] lg:mt-[2.4vw] ">
        <h3 className="text-[4.8vw] lg:text-[2.9vw] font-bold tracking-wider leading-normal lg:whitespace-pre-line">
          {title}
        </h3>
      </div>
      <div className="relative mt-[12.8vw] lg:mt-[4.4vw]">
        <div className="hidden lg:block overflow-hidden">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/about-pc_20240410183200.png`}
            alt=""
            width={1280}
            height={475}
            objectFit="cover"
          />
        </div>
        <div className="lg:hidden overflow-hidden">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/about-sp_20240410183200.png`}
            alt=""
            width={375}
            height={480}
            objectFit="cover"
          />
        </div>
        <div className="flex absolute after:absolute top-0 after:top-0 after:bottom-0 after:left-0 z-10 after:z-[-1] flex-col lg:flex-row w-full after:w-full h-full after:h-full after:bg-black after:opacity-20 after:contents-['']">
          <div className="flex flex-col justify-end lg:justify-center items-center pb-[8.5vw] lg:pb-0 w-full lg:w-[50.7vw] h-[64vw] lg:h-auto">
            <p className="before:block relative before:absolute before:top-1/2 before:left-0 pl-[2.9vw] lg:pl-[1.3vw] before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] text-[3.7vw] lg:text-[1.4vw] font-bold tracking-wider leading-none text-white before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
              定着・活躍を促進する
            </p>
            <p className="mt-[3.2vw] lg:mt-[1.5vw] text-[10.4vw] lg:text-[4.2vw] font-bold leading-none text-white">
              人材育成
            </p>
          </div>
          <div className="flex flex-col justify-end lg:justify-center items-center pb-[8.5vw] lg:pb-0 w-full lg:w-[50.7vw] h-[64vw] lg:h-auto">
            <p className="before:block relative before:absolute before:top-1/2 before:left-0 pl-[2.9vw] lg:pl-[1.3vw] before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] text-[3.7vw] lg:text-[1.4vw] font-bold tracking-wider leading-none text-white before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
              エンゲージメントを高める
            </p>
            <p className="mt-[3.2vw] lg:mt-[1.5vw] text-[10.4vw] lg:text-[4.2vw] font-bold leading-none text-white">
              組織開発
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block mx-auto lg:mt-[6.25vw] lg:w-[57.5vw]">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/about_20240410182300.png`}
          alt=""
          width={736}
          height={281}
        />
      </div>
      <div className="lg:hidden mx-auto mt-[21.3vw] w-[90vw]">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/about_20240410182300.png`}
          alt=""
          width={340}
          height={166}
        />
      </div>
      <section className="mx-[4.8vw] lg:mx-[7.1vw] mt-[12.5vw] lg:mt-[4.6vw]">
        <h4 className="text-[4.8vw] lg:text-[2.3vw] font-bold tracking-wider text-center text-[#EB8435] lg:text-black">
          バヅクリで
          <br className="lg:hidden" />
          解決できる課題
        </h4>
        <div className="flex flex-wrap lg:flex-nowrap lg:gap-[1.8vw] gap-x-[5.7vw] gap-y-[9.3vw] justify-center items-center mt-[7.4vw] lg:mt-[2.3vw]">
          <div className="pt-[1.6vw] lg:pt-[0.7vw] pb-[5.3vw] lg:pb-[2.8vw] w-[40vw] lg:w-[17.9vw] bg-white rounded-3xl card-box-shadow">
            <div className="p-[4.2vw] lg:p-[3.1vw] mx-auto w-[22.9vw] lg:w-[10.7vw] h-[22.9vw] lg:h-[10.7vw] bg-[#FBFBFB] rounded-full">
              <ImageWraper
                src={`${cloudfrontPath}/images/top/about_20240410144610.svg`}
                alt=""
                width={84}
                height={86}
              />
            </div>
            <p className="mt-[2.4vw] lg:mt-[1vw] text-[3.7vw] lg:text-[1.5vw] font-bold tracking-wider leading-none text-center">
              離職率改善
            </p>
          </div>
          <div className="pt-[1.6vw] lg:pt-[0.7vw] pb-[5.3vw] lg:pb-[2.8vw] w-[40vw] lg:w-[17.9vw] bg-white rounded-3xl card-box-shadow">
            <div className="p-[4.2vw] lg:p-[3.1vw] mx-auto w-[22.9vw] lg:w-[10.7vw] h-[22.9vw] lg:h-[10.7vw] bg-[#FBFBFB] rounded-full">
              <ImageWraper
                src={`${cloudfrontPath}/images/top/about_20240410144620.svg`}
                alt=""
                width={61}
                height={68}
              />
            </div>
            <p className="mt-[2.4vw] lg:mt-[1vw] text-[3.7vw] lg:text-[1.5vw] font-bold tracking-wider leading-none text-center">
              エンゲージメント改善
            </p>
          </div>
          <div className="pt-[1.6vw] lg:pt-[0.7vw] pb-[5.3vw] lg:pb-[2.8vw] w-[40vw] lg:w-[17.9vw] bg-white rounded-3xl card-box-shadow">
            <div className="p-[4.2vw] lg:p-[3.1vw] mx-auto w-[22.9vw] lg:w-[10.7vw] h-[22.9vw] lg:h-[10.7vw] bg-[#FBFBFB] rounded-full">
              <ImageWraper
                src={`${cloudfrontPath}/images/top/about_20240410144630.svg`}
                alt=""
                width={70}
                height={70}
              />
            </div>
            <p className="mt-[2.4vw] lg:mt-[1vw] text-[3.7vw] lg:text-[1.5vw] font-bold tracking-wider leading-none text-center">
              自律型人材育成
            </p>
          </div>
          <div className="pt-[1.6vw] lg:pt-[0.7vw] pb-[5.3vw] lg:pb-[2.8vw] w-[40vw] lg:w-[17.9vw] bg-white rounded-3xl card-box-shadow">
            <div className="p-[4.2vw] lg:p-[3.1vw] mx-auto w-[22.9vw] lg:w-[10.7vw] h-[22.9vw] lg:h-[10.7vw] bg-[#FBFBFB] rounded-full">
              <div className="">
                <ImageWraper
                  src={`${cloudfrontPath}/images/top/about_20240410144610.svg`}
                  alt=""
                  width={57}
                  height={64}
                />
              </div>
            </div>
            <p className="mt-[2.4vw] lg:mt-[1vw] text-[3.7vw] lg:text-[1.5vw] font-bold tracking-wider leading-none text-center">
              心理的安全性醸成
            </p>
          </div>
        </div>
      </section>
    </section>
  )
}
