import { SectionHeading } from '@components/ui/heading'
import { cloudfrontPath } from '@utils/image'
import React, { FC } from 'react'
import { HOME } from '../constant'
import { CircleArrowIcon } from '@components/ui/icon'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'

export const ServiceSection: FC = () => {
  return (
    <section className="mt-[25vw] lg:mt-[4.6vw]">
      <SectionHeading {...HOME.serviceSection.common.heading} />
      <div className="mx-[4.8vw] lg:mx-[7.1vw]">
        <div className="flex flex-col lg:flex-row lg:flex-wrap lg:gap-x-[2.5vw] gap-y-[6.4vw] mt-[3.8vw]">
          {HOME.serviceSection.common.cardItems.map(
            ({ title, description, src, href }) => (
              <a
                href={href}
                target="_blank"
                className="block w-full lg:w-[40vw] hover:opacity-50 cursor-pointer"
                key={title}
                rel="noreferrer"
              >
                <div className="flex-col rounded-[12px] card-box-shadow">
                  <div className="w-full h-[45vw] lg:h-[20vw] rounded-t-[12px]">
                    <ImageWraper
                      src={`${cloudfrontPath}/${src}`}
                      alt=""
                      className="object-cover w-full h-full rounded-t-[12px]"
                      width={528}
                      height={270}
                    />
                  </div>
                  <div className="flex flex-col px-[5.3vw] lg:px-[2.3vw] pt-[6.1vw] lg:pt-[2.3vw] pb-[6.1vw] lg:pb-[1.3vw] lg:h-[24vw] bg-white rounded-b-[12px]">
                    <h3 className="text-[4.2vw] lg:text-[1.7vw] font-bold tracking-wider whitespace-pre lg:whitespace-normal">
                      {title}
                    </h3>
                    <p className="mt-[2vw] lg:mt-[2.3] text-[3.2vw] lg:text-[1.25vw] font-bold tracking-widest leading-loose text-[#2C2C2C]">
                      {description}
                    </p>
                    <div className="relative py-[2.6vw] lg:py-[0.7vw] lg:px-[1.25vw] mx-auto mt-[6.4vw] lg:mt-auto w-[41.3vw] lg:w-[15.6vw] text-[3.7vw] lg:text-[1.25vw] font-bold text-center text-white bg-black bg-gradient-to-r from-primaryTo to-primaryFrom rounded-3xl">
                      詳しく見る
                      <div className="absolute top-1/2 right-[2.6vw] lg:right-[0.9vw] z-10 w-[3.4vw] lg:w-[1.4vw] h-[3.4vw] lg:h-[1.4vw] translate-y-[-50%]">
                        <CircleArrowIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )
          )}
        </div>
      </div>
    </section>
  )
}
