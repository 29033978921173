import { OrangeGradientDivide } from '@components/ui/divide'

const heading = {
  extraHeading: 'Contact',
  mainHeading: `バヅクリについて\nもっと知る`,
}

export const Heading = (): JSX.Element => {
  return (
    <>
      <div className="lg:block lg:mt-[7vw] lg:ml-[7.2vw]">
        <OrangeGradientDivide widthClass="lg:w-[9.3vw] hidden lg:block" />
        <div className="mt-[2vw] text-center lg:text-left">
          <div className="flex flex-col items-center lg:items-start font-heebo text-[5.8vw] lg:text-[4.1vw] tracking-[0.4vw] text-white">
            <span className="mb-[4.5vw] lg:mb-0">{heading.extraHeading}</span>
            <span className="inline-block lg:hidden w-[23.4vw] h-[2px] bg-gradient-to-r from-primaryTo to-primaryFrom"></span>
          </div>
        </div>
        <div className="mt-[3.5vw] text-[4.2vw] lg:text-[2.9vw] font-bold tracking-[0.2vw] leading-relaxed text-center lg:text-left text-white whitespace-pre">
          {heading.mainHeading}
        </div>
      </div>
    </>
  )
}
