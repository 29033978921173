import { SectionHeading } from '@components/ui/heading'
import React, { FC } from 'react'
import { Seminar } from '@type/buzzkuri/home'
import { addMinutes, format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { BtnWithCircleArrow } from '@components/ui/btn'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'

type Props = {
  heading: {
    mainHeading: string
    extraHeading: string
  }
  seminars: Seminar[]
}

const getEndTime = (start: string, minute: number) => {
  const endTime = addMinutes(new Date(start), minute)
  return format(new Date(endTime), 'HH:mm')
}

export const SeminarSection: FC<Props> = ({ heading, seminars }) => {
  return (
    <section className="mt-[19.2vw] lg:mt-[7vw]">
      <SectionHeading {...{ ...heading }} />
      <div className="flex flex-col lg:flex-row lg:gap-x-[3.5vw] gap-y-[6vw] lg:gap-y-0 mx-[7.1vw] mt-[4.1vw] rounded-[0.9vw]">
        {seminars.map((seminar) => (
          <a
            href={seminar.pageLink}
            target="_blank"
            className="block w-full lg:w-[24.8vw] h-full rounded-t-[.9vw] rounded-b-[.9vw] card-box-shadow"
            key={seminar.title}
            rel="noreferrer"
          >
            <div className="w-full lg:h-[13.2vw] rounded-t-[.9vw]">
              <ImageWraper
                src={seminar.imagePath}
                alt=""
                className="object-contain w-full h-full rounded-t-[.9vw]"
                width={311}
                height={150}
              />
            </div>
            <div className="p-[4vw] lg:p-[1vw] bg-white rounded-[0.9vw]">
              <div className="lg:h-[10.2vw] bg-white">
                <h3 className="text-[5vw] lg:text-[1.5vw] font-bold tracking-wide seminar-card-title-ellipsis">
                  {seminar.title}
                </h3>
                <p className="mt-[0.9vw] text-[3.2vw] lg:text-[1vw] tracking-wide leading-normal seminar-card-description-ellipsis">
                  {seminar.description}
                </p>
              </div>
              <div className="pt-[1vw] mt-[1vw] lg:mt-0 text-[3.2vw] lg:text-[1vw] border-t border-[#C8C8C8]">
                <span className="block tracking-wide text-[#EB8435]">
                  開催日時
                </span>
                <span className="font-bold tracking-wide">
                  {format(new Date(seminar.heldAt), 'yyyy年M月d日(E) HH:mm-', {
                    locale: ja,
                  })}
                  {getEndTime(seminar.heldAt, seminar.requiredTime)}
                </span>
              </div>
            </div>
          </a>
        ))}
      </div>
      <BtnWithCircleArrow
        text="一覧を見る"
        link="https://download.buzzkuri.com/engagement_portal"
        target="_blank"
      />
    </section>
  )
}
