import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { SectionHeading } from '@components/ui/heading'
import { cloudfrontPath } from '@utils/image'
import React, { FC } from 'react'
import { HOME } from '../constant'

type Props = typeof HOME.adviserSection.common

export const AdviserSection: FC<Props> = ({ heading }) => {
  return (
    <section className="pt-[17vw] lg:pt-[7.8vw] mt-[19.2vw] lg:mt-[7.8vw] bg-[#F7F7F7] rounded-t-[12.5vw]">
      <SectionHeading {...{ ...heading }} />
      <div className="flex flex-col gap-y-[8.5vw] lg:gap-y-[3.5vw] mx-[4.8vw] lg:mx-[11.25vw] mt-[12.8vw] lg:mt-[5.4vw]">
        {HOME.adviserSection.common.advisers.map((adviser, i) => (
          <div
            key={i}
            className="flex flex-col lg:flex-row justify-center items-center py-[2.3vw] px-[1.5vw] bg-white rounded-[20px] card-box-shadow"
          >
            <div className="flex flex-col items-center lg:mr-[2.5vw] lg:w-[21.5vw]">
              <div className="w-[61vw] lg:w-[15.7vw] h-[61vw] lg:h-[15.7vw]">
                <ImageWraper
                  src={`${cloudfrontPath}${adviser.img}`}
                  alt=""
                  width={149}
                  height={149}
                />
              </div>
              <p className="text-[3.2vw] lg:text-[0.9vw] font-bold tracking-wider text-center whitespace-pre">
                {adviser.label}
              </p>
              <p className="text-[4.2vw] lg:text-[1.25vw] font-bold tracking-widest text-center">
                {adviser.name}
              </p>
            </div>
            <div className="overflow-x-hidden overflow-y-scroll px-[3.7vw] lg:px-0 mt-[6.4vw] lg:mt-0 w-full lg:w-[48.7vw] h-[61vw] lg:h-[17.3vw]">
              <p className="text-[4vw] lg:text-[1.7vw] font-bold tracking-wide leading-normal lg:whitespace-pre">
                {adviser.title}
              </p>
              <p className="mt-2 lg:mt-5 text-[2.6vw] lg:text-[0.9vw] font-bold tracking-wider leading-loose ">
                {adviser.description}
              </p>
              {adviser.points.map((point, i) => (
                <div className="mt-2 font-bold tracking-wider" key={i}>
                  <p className="text-[3.7vw] lg:text-[1.1vw]">{point.title}</p>
                  <p className="mt-[8px] lg:mt-[11px] text-[2.6vw] lg:text-[0.9vw] leading-loose">
                    {point.message}
                  </p>
                </div>
              ))}
              {adviser?.profile && (
                <div className="mt-5 tracking-wider">
                  <p className="text-[4vw] lg:text-[1.1vw] font-bold leading-loose">
                    {adviser.profile.name}
                  </p>
                  <p className="text-[2.6vw] lg:text-[0.9vw] font-bold leading-loose">
                    {adviser.profile.description}
                  </p>
                  {adviser.profile?.book && (
                    <a
                      href={adviser.profile.book.link}
                      target="blank"
                      className="text-[2.4vw] lg:text-[0.8vw] underline"
                    >
                      著書：{adviser.profile.book.reference}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
