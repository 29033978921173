import { CASE_CAMPANISE } from '@components/buzzkuri/model/case'
import { ScreenTarget } from '@type/buzzkuri/home'
import { CaseCard } from '.'

type Props = {
  screenTarget: ScreenTarget
}

export const CaseCardGroups = ({ screenTarget }: Props): JSX.Element => {
  return (
    <div className="flex flex-wrap justify-center lg:mt-[3.3vw]">
      {CASE_CAMPANISE.map((item, i) => (
        <CaseCard key={i + 1} {...{ ...item }} {...{ screenTarget }} />
      ))}
    </div>
  )
}
