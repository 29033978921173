import { SectionHeading } from '@components/ui/heading'
import { PopularPrograms } from '@type/buzzkuri/home'
import React from 'react'
import { HOME } from '../constant'
import Link from 'next/link'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
type Props = {
  popularPrograms: PopularPrograms
}

export const PopularityProgramsSection = ({
  popularPrograms,
}: Props): JSX.Element => {
  return (
    <section className="mt-[17vw] lg:mt-[2.7vw]">
      <SectionHeading {...HOME.popularityProgramsSection.common.heading} />
      <div className="lg:mx-[7.1vw] mt-[12.8vw] lg:mt-[7.5vw] w-full">
        <section className="mt-[6.6vw] lg:mt-[2vw] ml-[4.8vw] lg:ml-0">
          <h3 className="before:block relative before:absolute before:top-1/2 before:left-0 pl-[2.9vw] lg:pl-[1.3vw] before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] text-[3.7vw] lg:text-[1.4vw] font-bold before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
            エンゲージメント向上
          </h3>
          <div className="flex overflow-x-auto overflow-y-hidden flex-row gap-[4.8vw] lg:gap-[1.4vw] mt-[2.4vw] lg:mt-[2.4vw] scrollbar-hide">
            {popularPrograms.engagementUp.map((item) => (
              <Link href={`/programs/${item.id}`} key={item.id}>
                <a target="_blank">
                  <div
                    className={`lg:w-[27.5vw] lg:h-[13.4vw] w-[63.7vw] h-[30vw] shadow-light lg:hover:opacity-50`}
                  >
                    <ImageWraper
                      src={item.imagePath}
                      alt=""
                      width={352}
                      height={172}
                    />
                  </div>
                </a>
              </Link>
            ))}
          </div>
        </section>
        <section className="mt-[6.6vw] lg:mt-[4.5vw] ml-[4.8vw] lg:ml-0">
          <h3 className="before:block relative before:absolute before:top-1/2 before:left-0 pl-[2.9vw] lg:pl-[1.3vw] before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] text-[3.7vw] lg:text-[1.4vw] font-bold before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
            心理的安全性の醸成
          </h3>
          <div className="flex overflow-x-auto overflow-y-hidden flex-row gap-[4.8vw] lg:gap-[1.4vw] mt-[2.4vw] lg:mt-[2.4vw] scrollbar-hide">
            {popularPrograms.pychologicalSafety.map((item) => (
              <Link href={`/programs/${item.id}`} key={item.id}>
                <a target="_blank">
                  <div
                    className={`lg:w-[27.5vw] lg:h-[13.4vw] w-[63.7vw] h-[30vw] shadow-light lg:hover:opacity-50`}
                  >
                    <ImageWraper
                      src={item.imagePath}
                      alt=""
                      width={352}
                      height={172}
                    />
                  </div>
                </a>
              </Link>
            ))}
          </div>
        </section>
        <section className="mt-[6.6vw] lg:mt-[4.5vw] ml-[4.8vw] lg:ml-0">
          <h3 className="before:block relative before:absolute before:top-1/2 before:left-0 pl-[2.9vw] lg:pl-[1.3vw] before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] text-[3.7vw] lg:text-[1.4vw] font-bold before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
            離職防止
          </h3>
          <div className="flex overflow-x-auto overflow-y-hidden flex-row gap-[4.8vw] lg:gap-[1.4vw] mt-[2.4vw] lg:mt-[2.4vw] scrollbar-hide">
            {popularPrograms.turnoverPrevention.map((item) => (
              <Link href={`/programs/${item.id}`} key={item.id}>
                <a target="_blank">
                  <div
                    className={`lg:w-[27.5vw] lg:h-[13.4vw] w-[63.7vw] h-[30vw] shadow-light lg:hover:opacity-50`}
                  >
                    <ImageWraper
                      src={item.imagePath}
                      alt=""
                      width={352}
                      height={172}
                    />
                  </div>
                </a>
              </Link>
            ))}
          </div>
        </section>
      </div>
    </section>
  )
}
