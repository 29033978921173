import Link from 'next/link'
import { CircleArrowIcon } from '@components/ui/icon'

type Props = {
  text: string
  link: string
  target?: string
}

export const BtnWithCircleArrow = ({
  text,
  link,
  target = '_self',
}: Props): JSX.Element => {
  return (
    <div className="flex justify-center items-center mt-[8.2vw] lg:mt-[2.3vw]">
      <Link href={link}>
        <a target={target}>
          <div className="flex justify-center items-center w-[61vw] lg:w-[31vw] h-[9vw] lg:h-[4.6vw] bg-gradient-to-r from-primaryTo to-primaryFrom rounded-[50px] shadow-light lg:hover:opacity-80">
            <div className="flex justify-between items-center mx-[6vw] lg:mx-[3.4vw] w-full">
              <div className="w-full text-center">
                <div className="text-[3.5vw] lg:text-[1.9vw] text-white">
                  {text}
                </div>
              </div>
              <div className="w-[4.5vw] lg:w-[1.8vw]">
                <CircleArrowIcon />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}
