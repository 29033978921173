import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { PreWraper } from '@components/shared/wraper/PreWraper'
import { CaseCardProps } from './type'
import Link from 'next/link'

export const CaseCard = ({
  title,
  campanyName,
  kv,
  logo,
  link,
}: CaseCardProps): JSX.Element => {
  return (
    <div className="mx-[4.5vw] lg:mx-[1.8vw] mb-[11.6vw] lg:mb-[3.9vw] w-[68vw] lg:w-[23.7vw]">
      <Link href={link}>
        <a className="lg:block lg:hover:opacity-80">
          <div></div>
          <div className="w-[68vw] lg:w-[23.7vw]">
            <ImageWraper
              className="rounded-t-lg"
              src={kv}
              key={kv}
              width={940}
              height={450}
            />
          </div>
          <div className="flex flex-col justify-center items-center px-[2px] pb-[2px] h-[28.5vw] lg:h-[9.9vw] rounded-b-lg shadow-light">
            <div className="flex flex-col justify-center items-center w-full h-full bg-white rounded-b-lg">
              <div>
                <PreWraper
                  classes="text-[#2C2C2C] font-bold text-center lg:text-[1.1vw] text-[3.7vw]"
                  text={title}
                />
              </div>
              <div className="flex items-center mt-[2vw] lg:mt-[.8vw] h-[8vw] lg:h-[3.6vw]">
                {!!logo.src && (
                  <div className="object-contain w-[17vw] lg:w-[5vw]">
                    <ImageWraper {...{ ...logo }} />
                  </div>
                )}
                <div className={`${logo.src ? 'ml-[3.2vw] lg:ml-[1vw]' : ''}`}>
                  <div className="max-w-[36vw] lg:max-w-[13vw] text-[2.7vw] lg:text-[0.9vw] text-[#666666] break-normal">
                    {campanyName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}
