import Link from 'next/link'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

type Props = {
  title: string
  href: string
  imagePath: string
}

export const ProgramCard = ({ title, imagePath, href }: Props): JSX.Element => {
  return (
    <Link href={href}>
      <a className="flex items-center py-[3.2vw] lg:py-[1.5vw] w-full border-t border-[#CCCCCC] hover:opacity-50">
        <div className="mr-[3.2vw] lg:mr-[1.8vw] w-[19.4vw] lg:w-[9.9vw] h-[13.3vw] lg:h-[6.5vw]">
          <ImageWraper
            alt=""
            src={imagePath}
            width={127}
            height={84}
            className="rounded-xl lg:rounded-3xl"
          />
        </div>
        <p className="text-[4.2vw] lg:text-[2.3vw] font-bold">{title}</p>
        <div className="ml-auto w-[4.5vw] lg:w-[2.5vw] lg:h-[2.5vw]">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/arrow-icon_20240409220700.svg`}
            width={32}
            height={32}
          />
        </div>
      </a>
    </Link>
  )
}
