import { PreWraper } from '@components/shared/wraper/PreWraper'

type Props = {
  title: string
  cardRowNum: number
}

export const FeedItemCardTitle = ({ title }: Props): JSX.Element => (
  <div className="lg:text-[16px] leading-normal">
    <h3 className="text-left">
      <PreWraper
        text={title}
        classes={`${'lg:text-[16px] text-[14px] leading-normal mt-[2.6vw] lg:mt-[0.7vw]'}`}
      />
    </h3>
  </div>
)
