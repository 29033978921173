import { ImageWraper } from '@components/shared/wraper/ImageWraper'

type Props = {
  imgSize: {
    w: number
    h: number
  }
  cardRowNum: number
  imagePath: string
  size?: 'small' | 'medium'
  title: string
}

export const FeedItemCardImage = ({
  imgSize,
  imagePath,
  title,
}: Props): JSX.Element => (
  <div className={`${'lg:w-[24.4vw]'}`}>
    <ImageWraper
      src={imagePath}
      alt={`${title}のイメージ画像`}
      width={imgSize.w}
      height={imgSize.h}
      objectFit="cover"
    />
  </div>
)
