import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

type Props = {
  setState: () => void
  containerClass: string
  btnClass: string
}

export const SliderBtn = ({
  setState,
  containerClass,
  btnClass,
}: Props): JSX.Element => {
  return (
    <div className={containerClass}>
      <button onClick={setState}>
        <div className={btnClass}>
          <ImageWraper
            src={`${cloudfrontPath}/images/top/slider-arrow.svg`}
            height={36}
            width={36}
          />
        </div>
      </button>
    </div>
  )
}
