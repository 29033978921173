import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

export const IntroductionCompanySection: React.FC = () => {
  return (
    <section className="relative mt-[10vw] lg:mt-[1.4vw]">
      <div className="flex">
        <div className="hidden lg:block pr-[3vw] animate-image-loop">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/introduction-company-list.png`}
            width={1865}
            height={150}
            layout="fixed"
          />
        </div>
        <div className="hidden lg:block pr-[3vw] animate-image-loop2">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/introduction-company-list.png`}
            width={1865}
            height={150}
            layout="fixed"
          />
        </div>
        <div className="block lg:hidden pr-[8vw] animate-image-loop">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/introduction-company-list-sp.png`}
            width={1190}
            height={201}
            layout="fixed"
          />
        </div>
        <div className="block lg:hidden pr-[8vw] animate-image-loop2">
          <ImageWraper
            src={`${cloudfrontPath}/images/top/introduction-company-list-sp.png`}
            width={1190}
            height={201}
            layout="fixed"
          />
        </div>
      </div>
      <div className="hidden lg:flex absolute after:absolute after:top-0 top-0 right-0 after:right-0 bottom-0 z-20 justify-end lg:items-center after:w-full lg:w-[21vw] after:h-full after:bg-white after:opacity-95 after:blur after:content-['']">
        <div className="relative z-10">
          <div className="lg:w-[15.6vw] lg:h-[9.6vw]">
            <ImageWraper
              src={`${cloudfrontPath}/images/top/batch-frame_20240409161900.png`}
              alt=""
              width={200}
              height={123}
            />
          </div>
          <p className="flex absolute top-1/2 left-1/2 z-20 flex-col justify-center items-center w-full h-full translate-x-[-50%] translate-y-[-50%]">
            <span className="lg:text-[1vw] font-bold tracking-wide leading-none">
              導入企業数
            </span>
            <span className="lg:text-[3.5vw] font-bold tracking-wider leading-tight text-[#000000CC]">
              800
            </span>
            <span className="lg:text-[1vw] font-bold tracking-wide leading-none">
              社以上
            </span>
          </p>
        </div>
      </div>
    </section>
  )
}
