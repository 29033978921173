import { StickButton } from '@components/ui/btn'
import { BgImages } from './components/BgImages'
import { Heading } from './components/Heading'

export const ContactSection: React.FC = () => {
  return (
    <section>
      <div className="relative mt-[16.9vw] lg:mt-[7.3vw] h-[86.9vw] lg:h-[42.8vw]">
        <BgImages />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between h-full">
            <div>
              <Heading />
            </div>
            <div className="flex flex-col justify-end items-center mt-[6.9vw] ">
              <StickButton btnType="freeTrial" />
              <StickButton btnType="DocumentDownload" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
