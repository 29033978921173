import { CaseCard } from '@components/ui/card'
import { ScreenTarget } from '@type/buzzkuri/home'
import { useState } from 'react'
import { CASE_TRANSLATEX_SIZE, INITIAL_STATE } from './conf'
import { SliderBtn } from './SliderBtn'
import { CaseActiveIndex } from './type'
import { CaseThumbnail } from '@components/buzzkuri/model/case'
import { useSwipeable } from 'react-swipeable'

type Props = {
  screenTarget: ScreenTarget
  caseItems: CaseThumbnail[]
}

export const CaseSlider = ({ screenTarget, caseItems }: Props): JSX.Element => {
  const [activeIdx, setActiveIdx] = useState<CaseActiveIndex>(INITIAL_STATE)

  const addState = () =>
    setActiveIdx((state) => {
      if (state === 0) {
        return (caseItems.length - 1) as CaseActiveIndex
      } else {
        return (state - 1) as CaseActiveIndex
      }
    })

  const subState = () =>
    setActiveIdx((state) => {
      if (state === caseItems.length - 1) {
        return 0
      } else {
        return (state + 1) as CaseActiveIndex
      }
    })

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      if (dir === 'Right') {
        addState()
      } else if (dir === 'Left') {
        subState()
      }
    },
  })

  return (
    <div className="relative" {...handlers}>
      <div
        className="flex relative mt-[4vw] transition-transform duration-[800ms]"
        style={{ transform: `translateX(${CASE_TRANSLATEX_SIZE[activeIdx]})` }}
      >
        {caseItems.map((item, i) => (
          <div key={i} className={i === activeIdx ? '' : 'opacity-60'}>
            <CaseCard {...{ ...item }} {...{ screenTarget }} />
          </div>
        ))}
      </div>
      <SliderBtn
        setState={addState}
        containerClass="absolute top-[22vw] left-[-3vw]"
        btnClass="w-[20vw] rotate-180"
      />
      <SliderBtn
        setState={subState}
        containerClass="absolute top-[22vw] right-[-3vw]"
        btnClass="w-[20vw]"
      />
    </div>
  )
}
