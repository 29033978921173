import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

export const BgImages = (): JSX.Element => {
  return (
    <>
      <div className="hidden lg:block lg:absolute top-0 left-0 z-0 lg:w-[82.3vw]">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/contact@2x.png`}
          width={1920}
          height={999}
        />
      </div>
      <div className="block lg:hidden absolute top-0 left-0 z-0 w-full">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/contact-sp@2x.png`}
          width={375}
          height={326}
        />
      </div>
    </>
  )
}
