import { OrangeGradientDivide } from '@components/ui/divide'
import { BTN_TYPE } from './conf'

type Props = {
  btnType: keyof typeof BTN_TYPE
}

export const StickButton = ({ btnType }: Props): JSX.Element => {
  const { text, link, iconElm } = BTN_TYPE[btnType]
  return (
    <a
      className="mb-[5.1vw] lg:mb-[2.9vw] bg-white rounded-l-xl"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex relative w-[67.5vw] lg:w-[44.6vw] h-[12.5vw] lg:h-[7.4vw] bg-white rounded-l-xl lg:hover:opacity-80">
        <div className="flex justify-between items-center px-[7vw] lg:px-[5.5vw] w-full">
          <div className="ml-[5.3vw] lg:ml-[2.7vw]">
            <div className="text-[4vw] lg:text-[2.5vw] font-bold lg:tracking-[0.2vw]">
              {text}
            </div>
          </div>
          <div className="w-[4.5vw] lg:w-[1.7vw]">{iconElm}</div>
        </div>

        <div className="absolute bottom-0 left-0 w-full h-[1.9vw] lg:h-[1.2vw] bg-gradient-to-r from-primaryTo to-primaryFrom rounded-bl-xl" />

        <div className="absolute top-1/2 left-[-11.1vw] lg:left-[-6.5vw]">
          <OrangeGradientDivide widthClass="lg:w-[13vw] w-[22.1vw]" />
        </div>
      </div>
    </a>
  )
}
