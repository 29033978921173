export const YahooAd = (): JSX.Element => (
  <>
    <script
      async
      src="https://s.yimg.jp/images/listing/tool/cv/ytag.js"
    ></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
        window.yjDataLayer = window.yjDataLayer || [];
        function ytag() { yjDataLayer.push(arguments); }
        ytag({
          "type":"yss_retargeting",
          "config": {
            "yahoo_ss_retargeting_id": "1001206696",
            "yahoo_sstag_custom_params": {
            }
          }
        });`,
      }}
    />
  </>
)
