import { COMPANY, SHAREHOLDER } from '@constants/buzzkuri/home'
import { clsx } from '@libs/clsx'
import { isArrLastIdx } from '@utils/array'
import { isOdd } from '@utils/number'
import React from 'react'

export const AboutUs: React.FC = () => {
  return (
    <div className="py-[9.6vw] lg:py-[3.3vw] w-full bg-[#F7F6F5]">
      <h2 className="text-center">
        <div className="text-[4.8vw] lg:text-[2.5vw] font-bold tracking-[0.3vw] lg:tracking-[0.2vw] text-[#2C2C2C]">
          会社概要
        </div>
      </h2>
      <div className="flex flex-col lg:flex-row mx-[6.4vw] lg:mx-[7.1vw] mt-[6.7vw] lg:mt-[6.1vw]">
        <div className="lg:mr-[4.2vw] lg:w-5/12">
          {COMPANY.map((obj, i) => (
            <div
              key={i}
              className="flex mb-[5vw] lg:mb-[3.8vw] text-[.9vw] lg:text-[1.3vw] tracking-[1.44px]"
            >
              <div className="w-[18vw] lg:w-[8.4vw] text-[2.9vw] lg:text-[1.1vw] tracking-[0.3vw] lg:tracking-[0.1vw] text-[#808080] flex-s">
                {obj.header}
              </div>
              <div
                className={clsx(
                  'flex-1 w-[69vw] lg:w-[31.2vw] text-[3.7vw] lg:text-[1.3vw] lg:font-bold tracking-[0.3vw] lg:tracking-[0.05vw] lg:leading-[2vw] text-[#2C2C2C]',
                  isArrLastIdx(COMPANY, i)
                    ? 'lg:w-[62.7vw]'
                    : isOdd(i)
                    ? 'lg:w-[28.8vw]'
                    : 'lg:w-[19.2vw]'
                )}
              >
                {obj.url ? (
                  <a
                    href={obj.url}
                    className="text-[3.7vw] lg:text-[1vw] text-[#333] lg:hover:text-[#e6553a]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {obj.body}
                  </a>
                ) : (
                  <div className="text-[3.7vw] lg:text-[1vw] lg:font-bold leading-normal lg:whitespace-pre">
                    {obj.body}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex">
          <div className="flex-shrink-0 w-[18vw] lg:w-[8.4vw] text-[2.9vw] lg:text-[1.1vw] tracking-[0.3vw] lg:tracking-[0.1vw] text-[#808080]">
            {SHAREHOLDER.header}
          </div>
          <div className="text-[3.7vw] lg:text-[1vw] lg:font-bold leading-normal lg:leading-[2rem] lg:whitespace-pre">
            {SHAREHOLDER.body}
          </div>
        </div>
      </div>
    </div>
  )
}
