import { FeedItemCardImage } from './Image'
import { FeedItemCardTitle } from './Title'
import Link from 'next/link'
import { NO_IMAGES } from '@constants/common'
import { cloudfrontPath } from '@utils/image'

type Props = {
  title: string
  description: string
  href: string
  imgSize: {
    w: number
    h: number
  }
  cardRowNum: number
  imagePath: string
  size?: 'small' | 'medium'
  isAnimation?: boolean
}

export const FeedItemCard = ({
  title,
  imgSize,
  cardRowNum,
  imagePath,
  size = 'medium',
  href,
}: Props): JSX.Element => {
  const imageUrl = !!imagePath
    ? imagePath
    : `${cloudfrontPath}${NO_IMAGES.rectangle}`
  return (
    <div className={`flex justify-center items-center font-bold`}>
      <Link href={href}>
        <a className="h-full">
          <div className="mb-[7vw] lg:mb-0 w-[74.4vw] lg:w-[24.2vw] lg:hover:opacity-50">
            <FeedItemCardImage
              {...{
                imgSize,
                imagePath: imageUrl,
                size,
                cardRowNum,
                title,
              }}
            />
            <FeedItemCardTitle {...{ title, size, cardRowNum }} />
          </div>
        </a>
      </Link>
    </div>
  )
}
