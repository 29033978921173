import { CaseActiveIndex } from './type'

export const CASE_TRANSLATEX_SIZE = {
  0: '12vw',
  1: '-65.5vw',
  2: '-142.5vw',
}

export const INITIAL_STATE = Number(
  Object.keys(CASE_TRANSLATEX_SIZE)[1]
) as CaseActiveIndex
