import { CircleArrowGrayIcon, DownloadIcon } from '@components/ui/icon'
import { DOCUMENT_DOWNLOAD_LINK_V2, CONTACT_LINK } from '@constants/common'

export const BTN_TYPE = {
  freeTrial: {
    text: 'お問合せ・お見積り',
    link: CONTACT_LINK,
    iconElm: <CircleArrowGrayIcon />,
  },
  DocumentDownload: {
    text: '無料資料ダウンロード',
    link: DOCUMENT_DOWNLOAD_LINK_V2,
    iconElm: <DownloadIcon />,
  },
}
