import { CASE_CAMPANISE } from '@components/buzzkuri/model/case'
import { ScreenTarget } from '@type/buzzkuri/home'
import { CaseSlider } from './CaseSlider'

type Props = {
  screenTarget: ScreenTarget
}

export const CaseSliderGroups = ({ screenTarget }: Props): JSX.Element => {
  return (
    <div>
      <CaseSlider
        caseItems={CASE_CAMPANISE.slice(0, CASE_CAMPANISE.length / 2)}
        {...{ screenTarget }}
      />
      <CaseSlider
        caseItems={CASE_CAMPANISE.slice(
          CASE_CAMPANISE.length / 2,
          CASE_CAMPANISE.length
        )}
        {...{ screenTarget }}
      />
    </div>
  )
}
