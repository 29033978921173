import React from 'react'
import { ProgramCard } from '../Card'

type Props = {
  programs: {
    imageFilePath: string
    name: string
    pagePath: string
  }[]
}

export const ProgramCards = ({ programs }: Props): JSX.Element => (
  <div className="flex flex-row flex-wrap mt-[12.5vw] lg:mt-[5.1vw] w-full border-b border-[#CCCCCC]">
    {programs.map((program, i) => (
      <ProgramCard
        key={i}
        title={program.name}
        href={`/programs/${program.name.replace('\n', '')}`}
        imagePath={program.imageFilePath}
      />
    ))}
  </div>
)
