import { Business } from '@type/buzzkuri/common'
import React from 'react'
import { BaseLayout } from '../Base'

type Props = {
  business: Business
  children: JSX.Element
}

export const HomeLayout = ({ business, children }: Props): JSX.Element => {
  return (
    <BaseLayout {...{ business }} isTopPage>
      <>
        <main className="overflow-x-hidden pb-[10vw] lg:pb-0 w-full">
          {children}
        </main>
      </>
    </BaseLayout>
  )
}
