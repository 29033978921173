import { MetaData } from '@type/buzzkuri/api'
import React from 'react'
import { NextSeo } from 'next-seo'

export const SeoMetadata = ({
  title,
  description,
  ogp,
}: MetaData): JSX.Element => (
  <NextSeo
    title={title}
    description={description}
    openGraph={{
      url: ogp.url,
      title: ogp.title,
      description: ogp.description,
      images: [
        {
          url: ogp.image,
          width: 1000,
          height: 680,
          alt: 'バヅクリブランドロゴ',
          type: ogp.type,
        },
      ],
      site_name: 'バヅクリ',
    }}
  />
)
