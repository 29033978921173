import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'

export const CircleArrowIcon = (): JSX.Element => {
  return (
    <ImageWraper
      src={`${cloudfrontPath}/images/top/circle-arrow.svg`}
      width={18}
      height={17}
    />
  )
}
