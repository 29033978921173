import { ProgramNavList } from '@type/buzzkuri/api'
import { useContext, useEffect } from 'react'
import { ProgramNavListContext } from './Cotenxt'

type Props = ProgramNavList

export const InitProgramNavList = ({
  totalProgramCount,
  programCategories,
}: Props): void => {
  const { setProgramNavList } = useContext(ProgramNavListContext)
  useEffect(() => {
    if (programCategories && totalProgramCount) {
      setProgramNavList({ totalProgramCount, programCategories })
    }
  }, [programCategories, setProgramNavList, totalProgramCount])
}
