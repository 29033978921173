import { AboutSection } from '@components/buzzkuri/pages/top/AboutSection'
import { ContactSection } from '@components/buzzkuri/pages/top/ContactSection'
import { HomeFirstViewSection } from '@components/buzzkuri/pages/top/FirstViewSection'
import { ArrivalColumnsSection } from '@components/buzzkuri/pages/top/ArrivalColumnsSection'
import { PointSection } from '@components/buzzkuri/pages/top/PointSection'
import { HomePageRes, ScreenTarget } from '@type/buzzkuri/home'
import { HOME } from './constant'
import React, { useContext, useEffect, useState } from 'react'
import { WindowSizeContext } from '@context/WindowSize/Context'
import { BREAKPOINTS } from '@constants/common'
import { HomeLayout } from '@components/buzzkuri/shared/layouts/Home'
import { IntroductionCompanySection } from './IntroductionCompanySection'
import { isValidProps } from '@utils/validate'
import { ProgramsPathsContext } from '@context/ProgramsPaths/Context'
import { InitProgramNavList } from '@context/ProgramNavList/InitProgramNavList'
import { CaseSection } from './CaseSection'
import { ServiceSection } from './ServiceSection'
import { AdviserSection } from './AdviserSection'
import { AboutUs } from '@components/buzzkuri/shared/common/AboutUs'
import { PopularityProgramsSection } from './PopularityProgramsSection'
import { ProgramSection } from './ProgramSection'
import { SeminarSection } from './SeminarSection'

export const HomePage = (props: HomePageRes): JSX.Element => {
  const { business, pageContent } = props
  const { totalProgramCount, programCategories, feedItems, popularPrograms } =
    pageContent
  const { arrivalColumnsSection } = HOME

  const winSize = useContext(WindowSizeContext)
  const [screenTarget, setScreenTarget] = useState<ScreenTarget>()

  useEffect(() => {
    if (winSize?.width) {
      setScreenTarget(winSize?.width >= BREAKPOINTS.laptop ? 'pc' : 'sp')
    }
  }, [winSize?.width])

  const { init } = useContext(ProgramsPathsContext)
  useEffect(() => init, [init])

  InitProgramNavList({ totalProgramCount, programCategories })

  return (
    <>
      {screenTarget && (
        <HomeLayout {...{ business }}>
          <>
            <HomeFirstViewSection />
            <IntroductionCompanySection />
            <AboutSection
              {...{
                ...HOME.aboutSection.common,
                ...HOME.aboutSection[screenTarget],
              }}
            />
            <PointSection {...HOME.pointSection.common} />
            <ServiceSection />
            <PopularityProgramsSection {...{ popularPrograms }} />
            <ProgramSection />
            <AdviserSection {...HOME.adviserSection.common} />
            <CaseSection {...{ screenTarget }} />
            {isValidProps(feedItems) && (
              <ArrivalColumnsSection
                {...arrivalColumnsSection.common}
                feedItems={feedItems}
              />
            )}
            {pageContent.seminars.length > 0 && (
              <SeminarSection
                {...HOME.seminarSection.common}
                seminars={pageContent.seminars}
              />
            )}
            <ContactSection />
            <AboutUs />
          </>
        </HomeLayout>
      )}
    </>
  )
}
