type Props = {
  extraHeading: string
  mainHeading: string
}

export const SectionHeading = ({
  extraHeading,
  mainHeading,
}: Props): JSX.Element => {
  return (
    <div className="mx-[4.8vw] lg:mx-[7.1vw]">
      <span className="inline-block pb-[4.2vw] lg:pb-[1.25vw] w-full font-heebo text-[3.7vw] lg:text-[1.4vw] tracking-wider text-[#EB8435] border-b border-[#CCCCCC]">
        <span className="before:block inline-block relative before:absolute before:top-1/2 before:left-0 pl-[4.6vw] lg:pl-[1.3vw] w-full before:w-[2.1vw] lg:before:w-[0.9vw] before:h-[2.1vw] lg:before:h-[0.9vw] leading-none before:bg-black before:bg-gradient-to-r before:from-primaryTo before:to-primaryFrom before:rounded-full before:translate-y-[-50%] before:content-['']">
          {extraHeading}
        </span>
      </span>
      {!!mainHeading && (
        <h2 className="mt-[5.6vw] lg:mt-[2.4vw] text-[4.8vw] lg:text-[2.9vw] font-bold tracking-widest">
          {mainHeading}
        </h2>
      )}
    </div>
  )
}
